<template>
  <!-- v-loading="loading" -->
  <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("account_recycle.title") }}</h4>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-refresh"
        plain
        @click="fatchRecord()"
        >{{ $t("base.refresh") }}</el-button
      >
    </div>

    <div class="select-zoom">
      <div class="select-item">
        <el-select
          v-model="select1"
          :placeholder='$t("account_recycle.select")'
          clearable
          filterable
          class="th-select"
          size="medium"
          style="width: 150px"
          @change="selectFn1"
        >
          <el-option :label='$t("account_recycle.select_val1")' :value="1"> </el-option>
          <el-option :label='$t("account_recycle.select_val2")' :value="2"> </el-option>
          <el-option :label='$t("account_recycle.select_val3")' :value="3"> </el-option>
        </el-select>
      </div>
    </div>

    <!-- 新列表 -->
    <div class="list-outline">
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="color-gray py-15 border-bottom font-14"
        style="padding-right: 20px; padding-left: 20px"
      >
        <el-col :span="6">
            {{ $t("account_recycle.span1") }}
        </el-col>
        <el-col :span="4">{{ $t("account_recycle.span2") }}</el-col>
        <el-col :span="4">{{ $t("account_recycle.span3") }}</el-col>
        <el-col :span="4">{{ $t("account_recycle.span4") }}</el-col>
        <el-col :span="4">{{ $t("account_recycle.span5") }}</el-col>
        <el-col :span="4" class="text-right">{{ $t("account_recycle.span6") }}</el-col>
        <!-- <el-col class="text-right" :span="4">{{
          $t("contact_about.table1.deal")
        }}</el-col> -->
      </el-row>

      <div class="contactlist-item" v-for="(item, index) in list" :key="index">
        <div
          class="contactlist-item-into"
          @click="item.flag = !item.flag"
          :class="item.flag ? 'contactlist-item-active' : ''"
        >
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="py-15 border-bottom font-14"
            style="padding-right: 20px; padding-left: 20px"
          >
            <el-col :span="6">
              <div class="coin-zoom">
                <span class="coin-zoom-icon">
                  <i class="el-icon-caret-bottom"></i> 
                </span>

                <div class="coin-zoom-into">
                  {{ item.order_no }}
                </div>
              </div>
            </el-col>
            <el-col :span="4">{{ item.usdt }}</el-col>
            <el-col :span="4">{{ item.currency }}</el-col>
            <el-col :span="4">{{ item.exchange_rate }}</el-col>
            <el-col :span="4">{{ item.currency_money }}</el-col>
            <el-col :span="4" class="text-right">
              {{
                item.status == 1
                  ? $t("account_recycle.status1")
                  : item.status == 2
                  ? $t("account_recycle.status2")
                  : item.status == 3
                  ? $t("account_recycle.status3")
                  : ""
              }}
            </el-col>
          </el-row>
          <div class="contactlist-item-detail">
            <div class="contactlist-item-detail-into">
              <div class="detail-row">
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h1") }}</span>
                  <span class="span-p">{{ item.name }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h2") }}</span>
                  <span class="span-p">{{ item.bank_name }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h3") }}</span>
                  <span class="span-p">{{ item.branch_name }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h4") }}</span>
                  <span class="span-p">{{ item.account }}</span>
                </div>
              </div>
              <div class="detail-row">  
                <div class="detail-col">
                  <span class="span-h">{{ "swift_code" }}</span>
                  <span class="span-p">{{ item.swift_code }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h5") }}</span>
                  <span class="span-p">{{ item.code }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h6") }}</span>
                  <span class="span-p">{{ item.bank_addres }}</span>
                </div>
                <!-- 汇款备注 -->
                <!-- <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h7") }}</span>
                  <span class="span-p">{{ item.remark }}</span>
                </div> -->
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h8") }}</span>
                  <span class="span-p">{{ item.create_time }}</span>
                </div>
              </div>  
              <!-- 凭证 -->
              <!-- <div class="detail-row">  
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h8") }}</span>
                  <span class="span-p">{{ item.create_time }}</span>
                </div>
                <div class="detail-col">
                  <span class="span-h">{{ $t("account_recycle.span_h9") }}</span>
                  <span class="span-p" @click.stop="stopFn">
                      <el-image 
                        :fit="'cover'"
                        style="width: 130px; height: 100px"
                        :src="item.image" 
                        :preview-src-list="[item.image]">
                      </el-image>
                  </span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新列表 -end -->

    <div class="pagination-outline">
      <div v-if="list.length < 1" class="py-50 font-14 color-i text-center">
        No More
      </div>
      <div class="pagination-box" v-else>
        <el-pagination
          layout="prev, pager, next"
          background
          :total="total1"
          :page-size="pageSize"
          :current-page="page"
          @current-change="pageFn1"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      record: {
        data: [],
      },
      state: [
        ["primary", "Trading"],
        ["info", "Cancel"],
        ["success", "Deal"],
      ],
      state_new: [
        this.$t("transactions.state_new1"),
        this.$t("transactions.state_new2"),
        this.$t("transactions.state_new3"),
        this.$t("transactions.state_new4"),
      ],
      list: [],
      page: 1,
      pageSize: 10,
      total1: 0,

      options1: [
        {
          value: 0,
          pair: this.$t("user_contract.rentext1"),
        },
        // {
        //   value: 1,
        //   pair: "BTC",
        // },
        // {
        //   value: 2,
        //   pair: "ETH",
        // },
        // {
        //   value: 3,
        //   pair: "DOGE",
        // },
      ],
      select1: "",

      options2: [
        {
          value: 0,
          label: this.$t("user_contract.rentext2"),
        },
        {
          value: 1,
          label: this.$t("user_contract.rentext3"),
        },
        {
          value: 2,
          label: this.$t("user_contract.rentext4"),
        },
      ],
      select2: 0,

      options3: [
        {
          value: 0,
          label: this.$t("user_contract.rentext5"),
        },
        {
          value: 1,
          label: this.$t("user_contract.rentext6"),
        },
        {
          value: 2,
          label: this.$t("user_contract.rentext7"),
        },
        {
          value: 3,
          label: this.$t("user_contract.rentext8"),
        },
        {
          value: 4,
          label: this.$t("user_contract.rentext9"),
        },
        {
          value: 5,
          label: this.$t("user_contract.rentext10"),
        },
      ],
      select3: 0,
      socket1: null,
    };
  },
  created: function () {
    // this.fatchRecord()
  },
  mounted: function () {
    this.fatchRecord();
  },
  destroyed: function () {},
  methods: {
    //合约订单
    fatchRecord: async function () {
      this.loading = true;
      // const { data } = await this.$request.get('transaction/order_pc/coin/'+this.$route.params.coin)
      const { data } = await this.$request.post("v1/deal/getOrderList", {
        page: this.page,
        pageSize: this.pageSize,
        type: 2,
        state: this.select1,
      });
      data.list.forEach(function (item, index) {
        item.flag = false;
      });
      this.list = data.list;
      this.total1 = data.total;
      this.loading = false;
    },

    pageFn1: function (e) {
      this.page = e;
      this.fatchRecord();
    },

    //
    selectFn1: function (e) {
      this.fatchRecord();
    },

    stopFn(){

    },
  },
};
</script>
<style lang="scss" scoped>
//
.pagination-outline {
  padding-top: 20px;
  box-sizing: border-box;
  text-align: center;
}
.newbtn {
  height: 32px;
  padding: 0 10px;
}
// .th-select input{
//     font-size: 14px;
//     font-weight: normal;
//     border: 0;
// }
.select-zoom {
  display: flex;
}
.select-item {
  margin-right: 20px;
  // width: 250px!important;
}
.select-zoom {
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6e9;
}

// 持仓列表
// <div class="coin-zoom">
//     <span class="coin-zoom-icon">
//     <i class="el-icon-caret-bottom"></i>
//     </span>
//     <div class="coin-zoom-into">
//     <strong>BTC / USDT</strong>
//     <span>Buy</span>
//     </div>
// </div>
.coin-zoom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.coin-zoom-into {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.coin-zoom-into strong {
  font-size: 14px;
  line-height: 14px;
}
.coin-zoom-icon {
  width: 14px;
  height: 14px;
  transition: all ease 0.5s;
}
.detail-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}
.detail-col {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
}
.span-h {
  font-size: 12px;
  color: #999;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}
.span-p {
  display: block;
  font-size: 14px;
  color: #333;
  line-height: 20px;
  font-weight: bold;
}
.detail-row:nth-child(1) .detail-col {
  width: 25%;
}
.detail-row:nth-child(1) .detail-col:nth-child(4) {
  text-align: right;
}
.detail-row:nth-child(2) .detail-col {
  width: 25%;
}
.detail-row:nth-child(2) .detail-col:nth-child(4) {
  text-align: right;
}
.detail-row:nth-child(3) .detail-col {
  width: 50%;
}
.contactlist-item-detail {
  background: #f1f1f1;
  overflow: hidden;
  height: 0px;
  transition: all ease 0.5s;
}
.contactlist-item-detail-into {
  padding: 10px 0;
  box-sizing: border-box;
}
.contactlist-item-active .coin-zoom-icon {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.contactlist-item-into {
  cursor: pointer;
}
.contactlist-item-active .contactlist-item-detail {
  // height: 300px;
  height: 200px;
}
</style>